import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";

import "./styles/contactUs.css";

const ContactUs = () => {
    return ( 
        <div>
            <body className="contactus-body" data-page="contact-us" >
                <NavBar />

                <div className="contact-section">
                    <div className="form-container">
                        <h2>Get In Touch</h2>
                        <p>We love hearing from our customers and clients. For general inquiries and product questions, please contact us using the form below.</p>
                        <form action="mailto:peterc@glitterpaper.com" method="post" enctype="text/plain">
                            <input type="text" placeholder="Your name" required />
                            <input type="email" placeholder="Email Address" required />
                            <textarea className="form-message" type="text" placeholder="Your Message" required />
                            <button type="submit">Post Message</button>
                        </form>
                    </div>
                    <div className="image-container-contact">
                        <img src="/contactUsImage.jpg" alt="contact-us-image" />
                    </div>
                </div>

                <div className="haveaproject">
                    <h2>Have A Project In Mind? Let’s Talk.</h2>
                    <p>If you are looking for a special manufacturing request, please contact us at <a href="mailto:sales@glitterpaperdesigns.com">sales@glitterpaperdesigns.com</a></p>
                    <div className="footer-grid">
                        <div className="footer-item">
                            <h2>Careers</h2>
                            <p>We are excited to have you join our team. Contact us at <a href="mailto:careers@glitterpaperdesigns.com">careers@glitterpaperdesigns.com</a></p>
                        </div>
                        <div className="footer-item">
                            <h2>Mailing Address</h2>
                            <p>125 Clairmont Avenue, Suite 460,<br />Decatur, Georgia, 30030, USA</p>
                        </div>
                        <div className="footer-item">
                            <h2>Sales</h2>
                            <p>Are you interested in partnering with us? <a href="mailto:sales@glitterpaperdesigns.com">sales@glitterpaperdesigns.com</a></p>
                        </div>
                    </div>
                </div>

                <Footer />
            </body>
        </div>
    )
}

export default ContactUs;
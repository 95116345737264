import React, { useState }from "react";


import "./Styles/navBar.css";

const NavBar = (props) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

	return (
		 <nav className="navbar">
			<div className="navbar-logo">
                <img
                    src="/glitterpaperdesignslogo.png"
                    alt="Logo"
                    className="logo"
                />
            </div>
			
            <ul className="nav-list">
                <li className="nav-item">
                    <a href="/">Home</a>
                </li>
                <li
                    className="nav-item dropdown"
                    onMouseEnter={toggleDropdown}
                    onMouseLeave={toggleDropdown}
                >
                    <a href="/product/wrap-and-ensembles" className="dropdown-toggle">Product</a>
                    {isDropdownOpen && (
                        <ul className="dropdown-menu">
                            <li className="dropdown-item">
                                <a href="/product/wrap-and-ensembles">Wraps & Ensembles</a>
                            </li>
                            <li className="dropdown-item">
                                <a href="/product/gift-bags">Gift Bags</a>
                            </li>
                            <li className="dropdown-item">
                                <a href="/product/gift-boxes">Gift Boxes</a>
                            </li>
							<li className="dropdown-item">
                                <a href="/product/greeting-cards">Greeting Cards</a>
                            </li>
							<li className="dropdown-item">
                                <a href="/product/gift-bows">Gift Bows</a>
                            </li>
							<li className="dropdown-item">
                                <a href="/product/cello-gift-wrap">Cello Gift Wrap</a>
                            </li>
							<li className="dropdown-item">
                                <a href="/product/gift-tag">Gift Tag</a>
                            </li>
							<li className="dropdown-item">
                                <a href="/product/partyware">Partyware</a>
                            </li>
                        </ul>
                    )}
                </li>

                <li className="nav-item">
                    <a href="/about-us">About Us</a>
                </li>

                <li className="nav-item">
                    <a href="/team">Team Page</a>
                </li>

                <li className="nav-item">
                    <a href="/sustainability">Sustainability</a>
                </li>

                <li className="nav-item">
                    <a href="/contact-us">Contact Us</a>
                </li>

            </ul>
        </nav>
	);
};

export default NavBar;
import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import ProductImages from "../Components/Common/productImages";
import partwareImage from "../Data/partywareImage";

import "./styles/partywareProduct.css";

const PartywareProduct = () => {
    return (
        <div>
            <NavBar />

            <div className="banner">
                <img src="/Partyware-1.jpg" alt="esms" className="partywarepage-banner"/>
            </div>
            
            <div className="productContent">
                <h2>Partyware</h2>
                <p>Introducing a recently added product collection to our broad range of capabilities is partyware. We can now provide fabulous partyware items to complete any celebration. From simple affordable items ideal for casual gatherings to unique parties requiring elevated finishing touches like foil accents.</p>
                <p>We can provide great designs for any season on plates, napkins, and tablecloths. Our partyware ensures that every celebration is complemented by stylish and well-coordinated essentials.</p>
            </div>

            <div className="partwareImage">
                <ProductImages picture={partwareImage[1].picture} alt="partwareImage" />
                <ProductImages picture={partwareImage[2].picture} alt="partwareImage" />
                <ProductImages picture={partwareImage[3].picture} alt="partwareImage" />
                <ProductImages picture={partwareImage[4].picture} alt="partwareImage" />
                <ProductImages picture={partwareImage[5].picture} alt="partwareImage" />
                <ProductImages picture={partwareImage[6].picture} alt="partwareImage" />
                <ProductImages picture={partwareImage[7].picture} alt="partwareImage" />
                <ProductImages picture={partwareImage[8].picture} alt="partwareImage" />
            </div>

            <Footer />
        </div>
    );
};

export default PartywareProduct;
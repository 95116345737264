import "./Styles/footer.css";


const Footer = (props) => {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-logo">
                    <img src="/glitterpaperdesignslogo.png" alt="Glitter Paper Designs Logo" />
                </div>
                <div className="footer-sections">
                    <div className="footer-section">
                        <h3>About Us</h3>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/about-us">About Us</a></li>
                            <li><a href="/sustainability">Sustainability</a></li>
                            <li><a href="/team">Team</a></li>
                            <li><a href="/contact-us">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Products</h3>
                        <ul>
                            <li><a href="/product/wrap-and-ensembles">Wrap and Ensembles</a></li>
                            <li><a href="/product/gift-bags">Gift Bags</a></li>
                            <li><a href="/product/gift-boxes">Gift Boxes</a></li>
                            <li><a href="/product/greeting-cards">Greeting Cards</a></li>
                            <li><a href="/product/gift-bows">Gift Bows</a></li>
                            <li><a href="/product/cello-gift-wrap">Cello Gift Wrap</a></li>
                            <li><a href="/product/gift-tag">Gift Tags</a></li>
                            <li><a href="/product/partyware">Partyware</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <address>
                            125 Clairemont Avenue, Suite 460<br />
                            Decatur, Georgia, 30030, USA<br />
                            (844) 580-8281<br />
                            <a href="mailto:sales@glitterpaperdesigns.com">sales@glitterpaperdesigns.com</a>
                        </address>
                        <div className="footer-social">
                            <a href="https://www.linkedin.com/company/glitter-paper-designs/"><img src="/linkedinlogo.png" alt="LinkedIn" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyright &copy; {new Date().getFullYear()} Glitter Paper Designs. All rights reserved</p>
            </div>
        </div>

    );
};

export default Footer;

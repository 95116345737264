import NavBar from "../Components/Common/navBar";
import ProductIcon from "../Components/Landing/productIcon";
import CompanyIcon from "../Components/Landing/companyIcon";
import Footer from "../Components/Common/footer";

import "./styles/landing.css";

const Landing = () => {
    return (	
        <div>
            <body data-page="home">
                <NavBar />

                <div className="homepage-banner">
                    <img
                        src="/homepage-banner.png"
                        alt="homepage-banner"
                        className="homepage-banner"
                    />
                </div>
                <div className="unique-designs">
                    <p className="Unique">Unique</p>
                    <p className="Designs">Designs</p>
                </div>

                

                <div className="homepage-text1">
                    Premier destination for design and product development. 
                    Our designs are meticulously created to captivate the eye and elevate the art of gifting.
                </div>
                
                <div className="icons">
                    <ProductIcon src="/Website-Icons-Color_Gift-Wrap.png" alt="Gift-Wrap" text="Gift Wrap" href='/product/wrap-and-ensembles'/>
                    <ProductIcon src="/Website-Icons-Color_Gift-Bag.png" alt="Gift-Bag" text="Gift Bag" href='/product/gift-bags'/>
                    <ProductIcon src="/Website-Icons-Color_Gift-Boxes.png" alt="Gift-Boxes" text="Gift Boxes" href='/product/gift-boxes'/>
                    <ProductIcon src="/Website-Icons-Color_Gift-Cards.png" alt="Gift-Cards" text="Gift Cards" href='/product/greeting-cards'/>
                    <ProductIcon src="/Website-Icons-Color_Party.png" alt="Party" text="Party" href='/product/partyware'/>
                    <ProductIcon src="/Website-Icons-Color_Gift-Bows.png" alt="Gift-Bows" text="Gift Bows" href='/product/gift-bows'/>
                    <ProductIcon src="/Website-Icons-Color_Cello-Gift-Wrap.png" alt="Cello-Gift-Wrap" text="Cello Wrap" href='/product/cello-gift-wrap'/>
                    <ProductIcon src="/Website-Icons-Color_Gift-Tags.png" alt="Gift-Tags" text="Gift Tags" href='/product/gift-tag'/>
                </div>

                <div className="homepage-content">
                    <div className="homepage-picture">
                        <img
                            src="/Lifestyle-3.jpg"
                            alt="Lifestyle-3"
                            className="lifestyle-img"
                        />
                    </div>

                    <div class="homepage-text">
                        <div class="text-item">
                            <p class="title">High Quality Product</p>
                            <p class="description">
                                We have unique print finishes that transform the simple act of presenting a gift into a delightful experience.
                            </p>
                        </div>

                        <div class="text-item">
                            <p class="title">Competitive Prices</p>
                            <p class="description">
                                Our direct-to-factory model not only enhances our competitiveness but also provides exceptional value to our customers.
                            </p>
                        </div>

                        <div class="text-item">
                            <p class="title">Lasting Relationships</p>
                            <p class="description">
                                Our mission is to build lasting relationships with our customers by providing quality product and unique design.
                            </p>
                        </div>
                    </div> 
                </div>

                <div className="companyIcon">
                    <CompanyIcon src="DollarGeneral_logo.png" alt="dollarGeneral" />
                    <CompanyIcon src="Walmart_logo.png" alt="walmart" />
                    <CompanyIcon src="DollarTree_logo.png" alt="dollarTree" />
                    <CompanyIcon src="Target_logo.png" alt="target" />
                    <CompanyIcon src="Dollarama_logo.png" alt="dollarama" />
                    <CompanyIcon src="HobbyLobby_logo.png" alt="hobbyLobby" />
                    <CompanyIcon src="LoBlaws_logo.png" alt="loblaws" />
                    <CompanyIcon src="FamilyDollar_logo.png" alt="familyDollar" />
                    <CompanyIcon src="Sam_logo.png" alt="samsClub" />
                    <CompanyIcon src="Michael_logo.png" alt="michales" />
                    <CompanyIcon src="WalmartCan_logo.png" alt="walmartCanada" />
                </div>
                
                <Footer />
            </body>
        </div>
    );
};

export default Landing;
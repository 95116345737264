import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import ProductImages from "../Components/Common/productImages";
import gifttagImages from "../Data/gifttagImage";

import "./styles/gifttagProduct.css";

const GifttagProduct = () => {
    return (
        <div>
            <NavBar />

            <div className="banner">
                <img src="/GiftTags_3.png" alt="esms" className="gifttagpage-banner"/>
            </div>
            
            <div className="productContent">
                <h2>Gift Tags</h2>
                <p>To add that heartfelt message we provide gift tags to match any of our gift wrap. We can design gift tags for any season and have a wide range of printing techniques and handmade applications to complete your package.</p>
                <p>We can include gift tags in composite gift solutions with gift wrap and bows as well as provide separate packs in coordinated collections or have a simple matching tag merchandized next to the gift wrap.  Tags can be designed for any specified retail and in many different packaging styles.</p>
            </div>

            <div className="gifttagImages">
                <ProductImages picture={gifttagImages[1].picture} alt="gifttagImages" />
                <ProductImages picture={gifttagImages[2].picture} alt="gifttagImages" />
                <ProductImages picture={gifttagImages[3].picture} alt="gifttagImages" />
                <ProductImages picture={gifttagImages[4].picture} alt="gifttagImages" />
                <ProductImages picture={gifttagImages[5].picture} alt="gifttagImages" />
                <ProductImages picture={gifttagImages[6].picture} alt="gifttagImages" />
                <ProductImages picture={gifttagImages[7].picture} alt="gifttagImages" />
                <ProductImages picture={gifttagImages[8].picture} alt="gifttagImages" />
            </div>

            <Footer />
        </div>
    );
};

export default GifttagProduct;
import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import ProductImages from "../Components/Common/productImages";
import giftbowImages from "../Data/giftbowImage";

import "./styles/giftbowProduct.css";

const GiftbowProduct = () => {
    return (
        <div>
            <NavBar />

            <div className="banner">
                <img src="/GiftBows-1.jpg" alt="esms" className="giftbow-banner"/>
            </div>
            
            <div className="productContent">
                <h2>Gift Bows</h2>
                <p>To complete our gift wrap collection we also provide the finishing touches to any gift wrap. Our bows can transform ordinary packages into beautiful gifts which displays the thoughtfulness invested in the art of gift giving.</p>
                <p>With a diverse range of bow types, sizes and complementing ribbons, our collection ensures a fabulous finishing touch for gifts of any size.  Bows and ribbons can be purchased together in a composite solution or can be sold separately in many different packaging styles.  
                </p>
            </div>

            <div className="giftbowImages">
                <ProductImages picture={giftbowImages[1].picture} alt="giftbowImages" />
                <ProductImages picture={giftbowImages[2].picture} alt="giftbowImage" />
                <ProductImages picture={giftbowImages[3].picture} alt="giftbowImage" />
                <ProductImages picture={giftbowImages[4].picture} alt="giftbowImage" />
                <ProductImages picture={giftbowImages[5].picture} alt="giftbowImage" />
                <ProductImages picture={giftbowImages[6].picture} alt="giftbowImage" />
                <ProductImages picture={giftbowImages[7].picture} alt="giftbowImage" />
                <ProductImages picture={giftbowImages[8].picture} alt="giftbowImage" />
            </div>

            <Footer />
        </div>
    );
};

export default GiftbowProduct;
import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import ProductImages from "../Components/Common/productImages";
import giftbagImages from "../Data/giftbagImage";

import "./styles/giftboxProduct.css";

const GiftbagProduct = () => {
    return (
        <div>
            <NavBar />

            <div className="banner">
                <img src="/GiftBagsEnsembles-1.jpg" alt="esms" className="giftbag-banner"/>
            </div>
            
            <div className="productContent">
                <h2>Gift Bags</h2>
                <p>Our mission is to etch an unforgettable memory in the hearts of all who receive our gift bags. Every facet is meticulously curated with the gifting experience in mind. From the intricate design and flawless finishes to the artfully handcrafted touches from the handles to the gift tags.</p>
                <p>We can design cohesive collections, and unique bundles or develop tailor-made single designs. Products can print with multiple finishes, or we can merely elevate the experience with the unique substrate.  The bags can be manufactured in a variety of sizes.</p>
            </div>

            <div className="giftbagImages">
                <ProductImages picture={giftbagImages[1].picture} alt="giftbagImage" />
                <ProductImages picture={giftbagImages[2].picture} alt="giftbagImage" />
                <ProductImages picture={giftbagImages[3].picture} alt="giftbagImage" />
                <ProductImages picture={giftbagImages[4].picture} alt="giftbagImage" />
                <ProductImages picture={giftbagImages[5].picture} alt="giftbagImage" />
                <ProductImages picture={giftbagImages[6].picture} alt="giftbagImage" />
                <ProductImages picture={giftbagImages[7].picture} alt="giftbagImage" />
                <ProductImages picture={giftbagImages[8].picture} alt="giftbagImage" />
            </div>

            <Footer />
        </div>
    );
};

export default GiftbagProduct;